<template>
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-sm-stretch">
        <h3 class="card-title">Captura del Cliente</h3>
      </div>
      <div class="card-body">
        <ul class="nav nav-tabs nav-line-tabs">
          <li class="nav-item">
            <a
              class="nav-link active"
              data-bs-toggle="tab"
              href="#kt_tab_pane_1"
              >Generales</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_2"
              >Contactos</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_3"
              >Proyectos</a
            >
          </li>
        </ul>

        <div class="tab-content">
          <div
            class="tab-pane fade active show"
            id="kt_tab_pane_1"
            role="tabpanel"
          >
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control form-nombre"
                    v-model="formaCliente.nombre"
                    id="forma_nombre"
                  />
                  <label for="forma_nombre">Nombre completo</label>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control form-rfc"
                    v-model="formaCliente.rfc"
                    id="forma_rfc"
                  />
                  <label for="forma_rfc">R.F.C</label>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control form-direccion"
                    v-model="formaCliente.direccion"
                    id="forma_direccion"
                  />
                  <label for="forma_direccion">Dirección</label>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control form-correo"
                    v-model="formaCliente.correo"
                    id="forma_correo"
                  />
                  <label for="forma_correo">Correo</label>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control form-telefono"
                    v-model="formaCliente.telefono"
                    id="forma_telefono"
                  />
                  <label for="forma_telefono">Teléfono</label>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control form-plazo"
                    v-model="formaCliente.plazo_pago"
                    id="forma_plazo"
                  />
                  <label for="forma_plazo">Plazo de pago</label>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
            <div class="card card-bordered mt-3">
              <div class="card-header">
                <div class="card-title-form">&nbsp;</div>
                <div class="card-toolbar">
                  <button
                    type="button"
                    class="btn btn-sm btn-light-dark"
                    @click="handleAgregarContacto"
                  >
                    Agregar contacto
                  </button>
                </div>
              </div>
              <div class="card-body">
                <table class="table table-sm table-bordered g-2">
                  <thead>
                    <tr class="table-active border">
                      <th>Nombre</th>
                      <th>Teléfono</th>
                      <th>Email</th>
                      <th>Tipo</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="border"
                      v-for="item in formaCliente.contactos"
                      :key="item.id"
                    >
                      <td>{{ item.nombre }}</td>
                      <td>{{ item.telefono }}</td>
                      <td>{{ item.email }}</td>
                      <td>{{ tipos.find((i) => i.id === item.tipo).tipo }}</td>
                      <td class="text-center">
                        <button
                          type="button"
                          class="btn btn-sm btn-xs btn-light-primary mx-2"
                          @click="
                            () => {
                              handleEditarContacto(item.id);
                            }
                          "
                        >
                          <i class="bi bi-pencil"></i>
                        </button>

                        <button
                          type="button"
                          class="btn btn-sm btn-xs btn-light-danger"
                          @click="
                            () => {
                              handleQuitarContacto(item.id);
                            }
                          "
                        >
                          <i class="bi bi-trash"></i>
                        </button>
                      </td>
                    </tr>
                    <tr class="border"></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
            <div class="card card-bordered mt-3">
              <div class="card-body">
                <table class="table table-sm table-bordered g-2">
                  <thead>
                    <tr class="table-active border">
                      <th>Proyecto</th>
                      <th>Estatus</th>
                      <th>Fecha de inicio</th>
                      <th>Trabajadores</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border" v-for="item in proyectos" :key="item.id">
                      <td>{{ item.proyecto }}</td>
                      <td>{{ item.estatus }}</td>
                      <td>{{ item.fecha_alta }}</td>
                      <td>{{ item.elementos }}</td>
                    </tr>
                    <tr class="border"></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <button
          type="button"
          class="btn btn-sm btn-primary"
          @click="handleGuardar"
        >
          GUARDAR
        </button>
      </div>
    </div>
  </div>

  <div class="modal fade" tabindex="-1" id="modalContactos">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Contacto</h3>

          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-1"></span>
          </div>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control form-contacto-nombre"
                  v-model="formaContacto.nombre"
                  id="forma_contacto_nombre"
                />
                <label for="forma_contacto_nombre">Nombre Completo</label>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control form-contacto-telefono"
                  v-model="formaContacto.telefono"
                  id="forma_contacto_telefono"
                />
                <label for="forma_contacto_telefono">Teléfono</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control form-contacto-email"
                  v-model="formaContacto.email"
                  id="forma_contacto_email"
                />
                <label for="forma_contacto_email">Correo Electrónico</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-floating mb-3">
                <select
                  class="form-select form-contacto-tipo"
                  v-model="formaContacto.tipo"
                  id="forma_contacto_tipo"
                >
                  <option value="0">Seleccionar</option>
                  <option v-for="tipo in tipos" :key="tipo.id" :value="tipo.id">
                    {{ tipo.tipo }}
                  </option>
                </select>
                <label for="forma_contacto_tipo">Tipo de contacto</label>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light btn-sm"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm"
            @click="handleSaveContacto"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import useClientes from "../composables/useClientes";
import { v4 as uuidv4 } from "uuid";

export default {
  props: ["cid"],
  setup(props) {
    const router = useRouter();
    const {
      busqueda,
      getClientes,
      formaClienteIni,
      getClienteInfo,
      setGuardarCliente,
      formaContactoIni,
      tipos,
      getClientesCatalogos,
    } = useClientes();
    const formaCliente = ref(formaClienteIni);
    const proyectos = ref([]);
    const formaContacto = ref(formaContactoIni);
    getClientesCatalogos();
    if (props.cid) {
      getClienteInfo(props.cid).then((result) => {
        if (result.ok) {
          formaCliente.value = { ...result.info };
          formaCliente.value.contactos = result.contactos;
          proyectos.value = result.proyectos;
        } else {
          return router.push({ name: "clientes" });
        }
      });
    } else {
      formaCliente.value = { ...formaClienteIni };
    }

    const handleGuardar = () => {
      setGuardarCliente({ ...formaCliente.value }).then((result) => {
        if (result.ok) {
          Swal.fire(
            "Exito",
            "Se ha guardado el cliente correctamente",
            "success"
          );
          getClientes(busqueda.value);

          return router.push({ name: "clientes" });
        } else {
          Swal.fire("Atención", result.message, "error");
        }
      });
    };

    const handleAgregarContacto = () => {
      formaContacto.value = { ...formaContactoIni };
      $("#modalContactos").modal("show");
    };

    const handleSaveContacto = () => {
      // Validaciones
      if (formaContacto.value.id.length <= 0) {
        formaContacto.value.id = uuidv4();
        formaCliente.value.contactos.push(formaContacto.value);
      } else {
        const idx = formaCliente.value.contactos.findIndex(
          (item) => item.id === formaContacto.value.id
        );
        formaCliente.value.contactos[idx] = { ...formaContacto.value };
      }

      $("#modalContactos").modal("hide");
    };

    const handleEditarContacto = (id) => {
      formaContacto.value = {
        ...formaCliente.value.contactos.find((item) => item.id === id),
      };
      $("#modalContactos").modal("show");
    };

    const handleQuitarContacto = (id) => {
      formaCliente.value.contactos = formaCliente.value.contactos.find(
        (item) => item.id !== id
      );
    };

    return {
      handleGuardar,
      formaCliente,
      proyectos,
      tipos,
      formaContacto,
      handleAgregarContacto,
      handleSaveContacto,
      handleQuitarContacto,
      handleEditarContacto,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>